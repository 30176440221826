<template>
  <b-modal
    hide-footer
    no-close-on-backdrop
    hide-header
    no-fade
    v-model="modalShow"
    size="xl"
    class="p-0"
  >
    <div class="">
      <div class="w-100 p-0">
        <div class="row p-0">
          <div class="col pb-5 px-0 welcome ml-4 mt-4 align-start">
            <img
              v-if="action === 'delete' || action === 'deleteOptimized'"
              src="@/assets/group-22.svg"
              alt
              class="welcome--image"
            />
            <img
              v-else
              src="@/assets/group-21.png"
              alt
              class="welcome--image"
            />
          </div>
          <div class="col px-5">
            <p class="title">
              <span v-if="action === 'delete'">Delete Model?</span>
              <span v-if="action === 'deleteOptimized'"
                >Delete Optimied Result?</span
              >
              <span v-else-if="action === 'add'">Enter a plan name</span>
              <span v-else-if="action === 'share'">Share plan details</span>
              <span v-else-if="action === 'saveResult'">Save Result</span>
            </p>
            <p v-if="action === 'delete'" class="subtitle">
              Hey {{ userName }}, are you sure to delete the model from the
              system?
            </p>
            <p v-if="action === 'deleteOptimized'" class="subtitle">
              Hey {{ userName }}, are you sure to delete the result from the
              system?
            </p>
            <div v-else-if="action === 'saveResult'">
              <b-form-input
                class="input-wrapper mb-5"
                placeholder="Plan Name"
                v-model="saveResultName"
              ></b-form-input>
            </div>
            <div v-else-if="action === 'add'">
              <b-form-input
                class="input-wrapper mb-5"
                placeholder="Enter a plan name"
                v-model="description"
              ></b-form-input>
              <div class="w-100 mb-5" v-if="action === 'add'">
               <!-- <w-dropdown
                  :options="action === 'add' ? modelList : userList"
                  :placeHolder="'Select'"
                  :labelText="action === 'add' ? 'Model' : 'Model'"
                  class=""
                  @input="selectModel($event)"
                ></w-dropdown> -->
              </div>
              <!--<div class="w-100">
                <w-dropdown
                  :options="action === 'add' ? planList : userList"
                  :placeHolder="'Select'"
                  :labelText="
                    action === 'add' ? 'Model saved in' : 'Share Model to'
                  "
                  class=""
                  @input="selectDropdown($event)"
                ></w-dropdown>
              </div>-->
            </div>

            <div
              class="d-flex w-100 m-0"
              :class="[
                action === 'delete'
                  ? 'justify-content-start'
                  : 'justify-content-end',
                action === 'deleteOptimized'
                  ? 'justify-content-start'
                  : 'justify-content-end',
              ]"
            >
              <div
                class="d-flex w-100"
                style="justify-content: space-between;align-items: center;"
              >
                <div @click="close" class="change-mind">
                  No, I changed my mind
                </div>
                <w-button
                  :buttonText="buttonText"
                  class="mt-5"
                  @buttonClicked="handleSave"
                  :isDisable="isDisabelModel"
                >
                </w-button>
              </div>
              <!--<button
                :class="{
                  'bg-red': action === 'delete',
                  'btn-effect-disabled': disabled,
                }"
                class="btn-effect"
                v-else
                @click="handleSave"
              >
                <span v-if="action === 'delete'" class="btn-text"
                  >Yes, delete</span
                >
                <span v-else class="btn-text">Save Plan</span>
                <i
                  class="fas fa-arrow-right ml-5 pl-4 d-flex"
                  style="align-items: center"
                ></i>
              </button>-->
            </div>
            <!--<div v-if="action === 'delete'" @click="close" class="change-mind">
              No, I changed my mind
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import Dropdown from "@/widgets/Dropdown.vue";
import Button from "@/components/Profile/Button.vue";
import moment from "moment";
export default {
  name: "SaveDigitalPlanModal",
  components: {
    // "w-dropdown": Dropdown,
    "w-button": Button,
  },
  data() {
    return {
      modalShow: false,
      saveResultName: "",
      selectedModel: {},
      userName: "",
      buttonText: "Save Plan",
      planList: [
        {
          text: "Personal Space",
          id: "Personal Space",
        },
        {
          text: "Shared Folder",
          id: "Shared Folder",
        },
      ],
      modelList: [],
      userList: [
        {
          text: "Harish Natarajan",
          id: "user",
          iconHTML: '<i class="fa fa-user"></i>',
        },
      ],
      description: "",
      action: "",
      selectedDropdown: {},
    };
  },
  computed: {
    isDisabelModel() {
        if (this.description === "default") {
            return true
        } 
        else if(this.description === "") {
          return true
        }
        // else {
        //     return false
        // }
      
    //   if (this.action === "delete" || this.action === "deleteOptimized") {
    //     return false;
    //   } else if (this.action === "saveResult") {
    //     if (this.saveResultName !== "") {
    //       return false;
    //     }
    //     return true;
    //   } 
    //   else {
    //     if (
    //       this.description !== "" &&
    //       // Object.keys(this.selectedDropdown).length !== 0 &&
    //       Object.keys(this.selectedModel).length !== 0
    //     ) {
    //       return false;
    //     }
    //   }

      return false;
    },
    // disabled() {
    //   if (this.action === "delete") {
    //     return false;
    //   } else {
    //     if (this.selectedDropdown === "Select") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
  },
  methods: {
    open(obj) {
      this.userName = sessionStorage.getItem("userName");
      this.modalShow = true;
      this.action = obj.action;

      if (obj.action === "delete") {
        this.buttonText = "Delete Model";
      }
      if (obj.action === "deleteOptimized") {
        this.buttonText = "Delete Result";
      } else if (obj.action === "saveResult") {
        this.buttonText = "Save Result";
      } else if(this.action === 'add') {
        this.modelList = obj.modelList;
        this.buttonText = "Save Plan";
      }
      if (this.action === "share") {
        this.description = "Diwali Campaign";
      }
    },
    close() {
      this.modalShow = false;
    },
    handleSave() {
      if (this.action === "delete" || this.action === "deleteOptimized") {
        this.$emit("deleteModel");
      } else if (this.action === "saveResult") {
        this.$emit("saveResult", this.saveResultName);
      } else {
        var data = {
          runId: sessionStorage.getItem("runId"),
          modelName: this.description,
          // space: this.selectedDropdown.id.toLowerCase(),
          model: this.selectedModel.id,
          modelId: this.selectedModel.text,
          saveDate: moment(new Date()).format("YYYY-MM-DD"),
          // emailId: sessionStorage.getItem('clientId')
        };
        this.$emit("saveModel", data);
      }
      this.close();
    },
   
  },
};
</script>
<style>
.modal.show .modal-dialog {
  margin-top: 0px;
}
</style>
<style scoped>
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  color: #222a37;
  font-size: 16px;
  font-family: ProximaNovaBold;
}
.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
}
.title {
  margin-top: 70px;
  font-size: 32px;
  font-family: ProximaNovaBold;
  line-height: 1.56;
  color: #222a37;
}
.subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  color: #9aafd4;
  margin-bottom: 24px;
}
.bg-red {
  background-color: #ff5a5a !important;
}
.change-mind {
  margin-top: 26px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #050505;
  text-decoration-line: underline;
  cursor: pointer;
}
.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 24px !important;
  object-fit: contain;
}

.btn-effect-disabled {
  opacity: 0.2;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>

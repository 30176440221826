var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"btn-back",on:{"click":_vm.backToCampaignDetails}},[_c('img',{attrs:{"src":require("@/assets/Icons/arrow-left-line.svg"),"alt":"back","height":"35"}})])]),_c('w-digital-plaiing-card-component',{attrs:{"title":"Plan Preference"}}),_c('div',{staticClass:"card dp-card"},[_vm._l((_vm.planOption),function(plan,index){return _c('div',{key:index},[_c('div',{staticClass:"dp-card-body"},[_c('w-digital-planning-card',{attrs:{"isActive":_vm.activeClass,"option":plan},on:{"optionClick":function($event){return _vm.getSelectedPlan($event)}}})],1),_c('div',{staticClass:"dp-card-body"},[_c('div',{style:(_vm.planPreference === 'Define Budget'
              ? 'display: block'
              : 'display: none')},[_c('label',{staticClass:"heading",attrs:{"for":"budgetBased"}},[_vm._v("Enter The Budget* ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.budgetBased),expression:"budgetBased"}],staticClass:"budget-card",attrs:{"type":"number","name":"budgetBased","step":1,"min":1000000},domProps:{"value":(_vm.budgetBased)},on:{"keypress":_vm.avoidDecimal,"change":function($event){return _vm.chnageInputValue($event)},"input":function($event){if($event.target.composing){ return; }_vm.budgetBased=$event.target.value}}}),_c('span',{staticClass:"heading"},[_vm._v("You have Entered "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.budgetBasedWords))]),_vm._v(" Budget")])]),_c('div',{style:(_vm.planPreference === 'Objective Based'
              ? 'display: block'
              : 'display: none')},[_c('span',[_vm._v("Select Objective* ")]),_vm._l((_vm.objectiveBased),function(option,index){return _c('div',{key:index},[_c('w-digital-planning-card',{attrs:{"isActive":_vm.engage,"option":option},on:{"optionClick":function($event){return _vm.getSelectedOptionObjective($event)}}})],1)}),_c('div',{style:(_vm.showObj === 'Inform & Engage'
                ? 'display: block'
                : 'display: none')},[_c('span',[_vm._v("Select Budget or Reach* ")]),_vm._l((_vm.informAndEngage),function(option,index){return _c('div',{key:index},[_c('w-digital-planning-card',{attrs:{"isActive":_vm.objClass,"option":option},on:{"optionClick":function($event){return _vm.getSelectedOptionInform($event)}}})],1)})],2),_c('div',{style:(_vm.showInform === 'Reach' ? 'display: block' : 'display: none')},[_vm._m(0,true),_vm._l((_vm.reach),function(budget,index){return _c('div',{key:index},[_c('input',{attrs:{"type":"radio","name":"budget","id":'engage' + budget},domProps:{"value":budget},on:{"click":function($event){return _vm.selectEngageReach(budget)}}}),_c('label',{attrs:{"for":'engage' + budget}},[_vm._v(_vm._s(budget))])])})],2)],2),_c('div',{style:(_vm.showObj === 'Inform & Action' ? 'display: block' : 'display: none')},[_c('span',[_vm._v("Select Budget or Reach* ")]),_vm._l((_vm.informAndEngage),function(option,index){return _c('div',{key:index},[_c('w-digital-planning-card',{attrs:{"isActive":_vm.action,"option":option},on:{"optionClick":function($event){return _vm.getSelectedOptionInformAction($event)}}})],1)})],2),_c('div',{style:(_vm.showAction === 'Action-Reach' ? 'display: block' : 'display: none')},[_vm._m(1,true),_vm._l((_vm.reach),function(budget,index){return _c('div',{key:index},[_c('input',{attrs:{"type":"radio","name":"Reach","id":'action' + budget},domProps:{"value":budget},on:{"click":function($event){return _vm.selectActionReach(budget)}}}),_c('label',{attrs:{"for":'action' + budget}},[_vm._v(_vm._s(budget))])])})],2),_c('div',{style:(_vm.showAction === 'Action-Budget'
              ? 'display: block'
              : 'display: none')},[_vm._m(2,true)]),_c('div',{style:(_vm.showAllocation ? 'display: block' : 'display: none')},[_vm._m(3,true),_vm._m(4,true)])])])}),_c('center',[_c('button',{staticClass:"submit-button",on:{"click":_vm.nextToAssets}},[_vm._v("Continue")])])],2),_c('div')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Select The Reach ("),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("in %")]),_vm._v(")*")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Select The Reach ("),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("in %")]),_vm._v(")*")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Select The Budget ("),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("in Lacs")]),_vm._v(")")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("Budget Allocation*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',[_c('label',[_vm._v(" Inform : ")]),_c('input',{attrs:{"type":"number"}}),_c('label',[_vm._v(" %")])])]),_c('div',[_c('div',[_c('label',[_vm._v(" Action :")]),_c('input',{attrs:{"type":"number"}}),_c('label',[_vm._v(" %")])])])])}]

export { render, staticRenderFns }
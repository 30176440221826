<template>
  <div>
    <div
      class="option-box cursor-pointer"
      :class="{ active: isActive === option }"
      @click="getSelectedOption"
    >
      <div>
        <div class="text" style="font-size: 22px;">
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["option", "isActive"],
  methods: {
    getSelectedOption() {
      this.$emit("optionClick", this.option);
    },
  },
};
</script>
<style scoped>
.button-title {
  font-size: 18px;
  font-weight: bold;
  flex-direction: row;
}
.button:hover{
  background: #c8f0d1;
}

.button:hover .check {
  opacity: 1;
}
.active i {
  color: #4BB543;
}
.button.active {
  background-color: #c8f0d1;
}
.active .check {
  opacity: 1;
}
.check {
  margin: 1rem;
}
.check i {
  color: rgb(217, 46, 114);
}

.option-box:hover {
  opacity: 0.9;
}
.check {
  text-align: end;
  line-height: 13px;
  margin-bottom: 5px;
  opacity: 0;
}
.check i {
  color: rgb(217, 46, 114);
}
.option-box:hover .check {
  opacity: 1;
}
.active i {
  color: #4BB543;
}
.option-box.active {
  background-color: #ffffff;
  border: 3px solid;
  border-color: rgb(217, 46, 114);
  color: #000;
}
.active .check {
  opacity: 1;
}
.option-box {
   background: none;
  border-radius: 5px;
  color: #fff;
  background: rgb(217, 46, 114);
  display: block;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
  padding: 4rem;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
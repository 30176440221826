var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"btn-back",on:{"click":_vm.back}},[_c('img',{attrs:{"src":require("@/assets/Icons/arrow-left-line.svg"),"alt":"back","height":"35"}})])]),_c('DigitalPlanningCardComponent',{attrs:{"title":"Campaign Details"}}),_c('div',{staticClass:"card dp-card",staticStyle:{"margin-bottom":"20px"}},[_c('center',{staticClass:"child-container"},[_c('div',{staticClass:"column"},[_c('h2',{staticClass:"section-title"},[_vm._v("Gender")])]),_c('div',{staticClass:"column"},[_c('h2',{staticClass:"section-title"},[_vm._v("Age")])]),_c('div',{staticClass:"column"},[_c('h2',{staticClass:"section-title"},[_vm._v("Markets")])])]),_c('div',{staticClass:"row",style:(_vm.selectedCampaign === 'TV + Digital'
          ? 'border-right: 1px solid'
          : 'border-left: 0px solid')},[_c('div',{staticClass:"checkbox-options"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"options"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.genders),function(gender,index){return _c('div',{key:index},[_c('input',{staticStyle:{"background-color":"#000"},attrs:{"type":"checkbox","name":gender.value,"id":gender.value},domProps:{"value":gender.value,"checked":gender.checked},on:{"click":function($event){return _vm.selectGender($event, gender.name)}}}),_c('label',{staticClass:"text gender",attrs:{"for":gender.value}},[_vm._v(_vm._s(gender.name))]),_c('br')])}),0)])]),_c('div',[_c('div',{staticClass:"column"},[_c('div',{staticClass:"options"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.ageOption),function(age,index){return _c('div',{key:index},[_c('input',{attrs:{"type":"checkbox","name":age.value,"id":age.value},domProps:{"value":age.value,"checked":age.checked},on:{"click":function($event){return _vm.selectAge($event, age.value)}}}),_c('label',{staticClass:"gender text",attrs:{"for":age.value}},[_vm._v(_vm._s(age.name))]),_c('br')])}),0)])])]),(_vm.selectedCampaign === 'TV + Digital')?_c('div',[_c('div',{staticClass:"column"},[_vm._m(0),_c('div',{staticClass:"options"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.selectTvMarket),function(market,index){return _c('div',{key:index},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":'tvonly' + market.name,"name":market.name},domProps:{"value":market.name},on:{"click":function($event){return _vm.selectedTvMarketList(market.name, $event)}}}),_c('label',{attrs:{"for":'tvonly' + market.name}},[_vm._v(_vm._s(market.name))])]),_c('div',[_c('input',{class:{
                        disableInput: !_vm.selectedtvMarket.includes(market.name),
                      },attrs:{"type":"number","name":market.name,"disabled":!_vm.selectedtvMarket.includes(market.name)},domProps:{"value":market.value},on:{"input":function($event){return _vm.getTvMarketValue($event, market.name)}}})])])}),0)])])]):_vm._e(),(_vm.selectedCampaign === 'TV + Digital')?_c('div',[_c('div',{staticClass:"column"},[_vm._m(1),_c('div',{staticClass:"options"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.frequencyDetails),function(frequency,index){return _c('div',{key:index},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":frequency.name,"name":frequency.value},domProps:{"value":frequency.value},on:{"click":function($event){return _vm.selectFrequency($event, frequency)}}}),_c('label',{attrs:{"for":frequency.name}},[_vm._v(_vm._s(frequency.name))])]),_c('div',[_c('input',{style:(!_vm.selectedFrequency.includes(frequency.name)
                          ? 'color: transparent;'
                          : 'color: black;'),attrs:{"type":"number","name":frequency.value,"disabled":!_vm.selectedFrequency.includes(frequency.name)},domProps:{"value":frequency.value},on:{"input":function($event){return _vm.getFrequencyValue($event, frequency.name)}}})])])}),0)])])]):_vm._e(),(_vm.selectedCampaign === 'TV + Digital')?_c('div',[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title flex"},[_c('span',[_vm._v("Digital Market ("),_c('span',{staticClass:"hiper-link ml-1",on:{"click":function($event){return _vm.chnageDigitalMarket(_vm.tvMarketShow)}}},[_vm._v("Change")]),_vm._v(")")]),_c('span',{staticClass:"mr-3"},[_vm._v("Priority")])]),_c('div',{staticClass:"options"},[(
                  _vm.selectDigitalMarket.length === 0 &&
                    _vm.selectedtvMarket.length === 0
                )?_c('div',[_c('span',{staticClass:"title"},[_vm._v("Please select TV markets")])]):_vm._e(),(
                  _vm.selectDigitalMarket.length === 0 &&
                    _vm.selectedtvMarket.length !== 0
                )?_c('div',[_c('span',{staticClass:"title"},[_vm._v("Do you want digital markets same as TV markets")]),_c('div',{staticClass:"mt-4",staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.defaultMarket}},[_vm._v(" No ")]),_c('button',{staticClass:"btn btn-primary ml-4",on:{"click":_vm.tvMarket}},[_vm._v(" Yes ")])])]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.selectDigitalMarket),function(market,index){return _c('div',{key:index,staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":'digital' + market.name,"name":market.name},domProps:{"value":market.name,"checked":market.checked},on:{"click":function($event){return _vm.selectDigitalTvMarketList($event, market.name)}}}),_c('label',{staticClass:"gender text",attrs:{"for":'digital' + market.name}},[_vm._v(_vm._s(market.name))])]),_c('div',[_c('w-dropdown',{style:(!_vm.selectedDigitalTvMarket.includes(market.name)
                          ? 'color: transparent'
                          : 'color: black'),attrs:{"options":_vm.priorityOptions,"selectedOption":market.priority,"disableDropdown":!_vm.selectedDigitalTvMarket.includes(market.name)},on:{"selectedOption":function($event){return _vm.selectDigitalTvPriority($event, market.name)}}})],1)])}),0)])])]):_vm._e(),(_vm.selectedCampaign === 'Digital Only')?_c('div',[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title flex"}),_c('div',{staticClass:"options"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.selectDigitalMarketOption),function(market,index){return _c('div',{key:index},[_c('div',[_c('input',{attrs:{"type":"radio","id":'digitalonly' + market.value,"name":"market.value","disabled":market.isDisable},domProps:{"value":market.value,"checked":market.checked},on:{"click":function($event){return _vm.selectDigitalMarketList($event, market.name)}}}),_c('label',{staticClass:"gender text",attrs:{"for":'digitalonly' + market.value}},[_vm._v(_vm._s(market.name))])]),_c('div')])}),0)])])]):_vm._e()]),_c('center',[_c('button',{staticClass:"submit-button",on:{"click":_vm.next}},[_vm._v("Continue")])])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title flex"},[_c('span',[_vm._v("TV Market")]),_vm._v(" "),_c('span',{staticClass:"mr-5"},[_vm._v("GRP")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title flex"},[_c('span',[_vm._v("Frequency")]),_vm._v(" "),_c('span',{staticClass:"mr-3"},[_vm._v("Reach")])])}]

export { render, staticRenderFns }
<template>
  <div>
    <div class="mb-3">
      <div class="btn-back" @click="backAvailableAssets">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <div class="mb-5">
      <div class="mb-3">
        <div
          style="cursor: pointer"
          v-for="(tab,index) in tabs"
          :key="index"
          @click="showTabContent(tab.name)"
        >
          <w-digital-planning-card-component :title="tab.name" />
          <!---   <span
              :style="
                selectedTabs.includes(tab.name) ? 'opacity: 1' : 'opacity: 0'
              "
              ><i class="fas fa-check" style="color: #4bb543"></i>
            </span> --->
        </div>
        <div>
          <div
            class="tab mt-5 ml-4"
            :style="
              selectedTab === 'Business Type'
                ? 'display: flex'
                : 'display: none'
            "
          >
            <form @submit.prevent>
              <div class="mb-3 heading">Business Type</div>
              <div v-for="(businessType, index) in businessTypes" :key="index">
                <input
                  type="radio"
                  name="businessType"
                  class="mr-2 ml-5 mb-3"
                  :value="businessType"
                  :id="businessType"
                  @click="selectBusinessType(businessType)"
                />
                <label :for="businessType" class="label text">{{
                  businessType
                }}</label>
              </div>
            </form>
          </div>
          <div
            class="tab mt-5 ml-4"
            :style="
              selectedTab === 'Brand Strength'
                ? 'display: block'
                : 'display: none'
            "
          >
            <div class="mb-3 heading" style="margin-left: -11px">
              Brand Strength
            </div>
            <div class="mt-4">
              <div
                v-for="(brandStrength, index) in brandStrengths"
                :key="index"
              >
                <input
                  type="radio"
                  name="brandStrength"
                  class="mr-2 ml-5 mb-3"
                  :value="brandStrength"
                  :id="brandStrength"
                  @click="selectBrandStrength(brandStrength)"
                />
                <label :for="brandStrength" class="label text">{{
                  brandStrength
                }}</label>
              </div>
            </div>
          </div>
          <div
            class="tab mt-5 ml-4"
            :style="
              selectedTab === 'Brand Premieumness'
                ? 'display: block'
                : 'display: none'
            "
          >
            <div class="mb-3 heading" style="margin-left: -11px">
              Brand Premieumness
            </div>
            <div class="mt-4">
              <div v-for="(brand, index) in brands" :key="index">
                <input
                  type="radio"
                  name="brand"
                  :id="brand"
                  class="mr-2 ml-5 mb-3"
                  @click="selectBrandPremieumness(brand)"
                  :value="brand"
                />
                <label :for="brand" class="label text">{{ brand }}</label>
              </div>
            </div>
          </div>
          <div
            class="tab"
            :style="
              selectedTab === 'Brand Category'
                ? 'display: block'
                : 'display: none'
            "
          >
            <div class="card dp-card">
              <div class="mb-3 heading">Brand Category</div>
              <div class="mt-4">
                <div v-for="(brand, index) in brandCat" :key="index">
                  <input
                    type="radio"
                    name="brandCat"
                    class="mr-2 ml-5 mb-3"
                    :id="brand.name"
                    @click="selectBrandCat(brand.value)"
                    :value="brand.value"
                  />
                  <label :for="brand.name" class="dp-label">{{
                    brand.name
                  }}</label>
                </div>
                   <center>
      <button class="submit-button" @click="submitData">Continue</button>
    </center>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DigitalPlanningCardComponent from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent";
export default {
  //props:["brandCat"],
  components: {
    "w-digital-planning-card-component": DigitalPlanningCardComponent,
  },
  data() {
    return {
      selectedTab: "Brand Category",
      tabs: [
        { name: "Brand Category", class: "fa-users" },
        //{ name: "Business Type", class: "fa-newspaper" },
        //{ name: "Brand Strength", class: "fa-tv" },
        //{ name: "Brand Premieumness", class: "fa-tag" },
      ],
      selectedTabs: [],
      showBlock: "Brand Category",
      brandStrengths: [
        "Top 2",
        "Top 3-5",
        "Top 6-10",
        "Top 11-20",
        "Not in Top 20",
      ],
      brands: ["Regular", "Mastige", "Luxury", "Premieum"],
      brandCat: [
        //{name:"CPG", value: 'CPG'},
        // { name: "Autos & Vehicles", value: "Autos & Vehicles" },
        // { name: "Health & Pharmacy", value: "Health & Pharmacy" },
        // {
        //   name: "Beauty & Fashion & Fitness",
        //   value: "Beauty & Fashion & Fitness",
        // },
        // { name: "BFSI", value: "BFSI" },
        // {
        //   name: "Computers & Mobiles & Accesssories",
        //   value: "Computers & Mobiles & Accesssories",
        // },
        // { name: "Consumer Durables", value: "Consumer Durables" },
        // { name: "Consumer Electronics", value: "Consumer Electronics" },
        // { name: "Food & Drink", value: "Food & Drink" },
        // { name: "Shopping", value: "Shopping" },
        // { name: "Travel", value: "Travel" },
        { name: "Generic", value: "generic" },
        //{name:"Banking", value: 'Banking'},
        //{name:"FMCG", value: 'FMCG'},
      ],
      businessTypes: ["B2B", "B2C"],
      selectedBusinessType: "",
      selectedBrandStrength: "",
      selectedPremieumness: "",
      selectedBrandCat: "",
    };
  },
  methods: {
    // getSelectedOption(value) {
    //   this.showBlock = value;
    // },
    selectBrandStrength(value) {
      this.selectedBrandStrength = value;
      if (!this.selectedTabs.includes("Brand Strength")) {
        this.selectedTabs.push("Brand Strength");
      }
    },
    selectBrandPremieumness(value) {
      this.selectedPremieumness = value;
      if (!this.selectedTabs.includes("Brand Premieumness")) {
        this.selectedTabs.push("Brand Premieumness");
      }
    },
    selectBrandCat(value) {
      this.selectedBrandCat = value;
      if (!this.selectedTabs.includes("Brand Category")) {
        this.selectedTabs.push("Brand Category");
      }
    },
    selectBusinessType(value) {
      this.selectedBusinessType = value;
      if (!this.selectedTabs.includes("Business Type")) {
        this.selectedTabs.push("Business Type");
      }
    },
    showTabContent(name) {
      this.selectedTab = name;
    },
    submitData() {
      if (
        //this.selectedBusinessType === "" ||
        //this.selectedBrandStrength === "" ||
        //this.selectedPremieumness === "" ||
        this.selectedBrandCat === ""
      ) {
        return true;
      }
      var businessData = {
        businessType: this.selectedBusinessType,
        brandStrength: this.selectedBrandStrength,
        brandPremium: this.selectedPremieumness,
        brandCategory: this.selectedBrandCat,
        planName: "default",
        userName: sessionStorage.getItem("clientId"),
      };
      this.$emit("submitData", businessData);
    },
    backAvailableAssets() {
      this.$emit("backAvailableAssets", true);
    },
  },
};
</script>

<style scoped>
.tabList .nav-link.active,
.tabList .nav-link:hover {
  border-bottom: 3px solid #d92e72;
}
.tabList .nav-link {
  color: inherit;
  font-size: 1rem;
}
.nav-link {
  display: block;
  padding-bottom: 0.5rem;
}
span {
  cursor: pointer;
  text-decoration: none;
}
.tabList .nav-link span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.tabList .nav-link span:nth-child(1) {
  font-size: 2rem;
  color: #d92e72;
}
.option-box:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  background-color: #344276;
  opacity: 1;
}
.check {
  text-align: end;
  line-height: 13px;
  margin-bottom: 5px;
  opacity: 0;
}
.text.title {
  font-size: 22px;
}
.check i {
  color: rgb(217, 46, 114);
}
.option-box:hover .check {
  opacity: 1;
}
.active i {
  color: rgb(217, 46, 114);
}
.tabList {
  border-bottom: 1px solid #ccc;
}
.dp-card {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.heading {
  font-size: 18px;
  font-weight: bold;
  font-family: ProximaNovaBold;
  color: black;
  margin-top: 2rem;
}
.dp-label {
  font-size: 15px;
  margin: 1rem;
  color: black;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  align-items: center;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.submit-button:hover {
opacity: 0.9;
}
.button {
  display: flex;
  align-self: center;
}
</style>
